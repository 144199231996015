import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import ProductIllustration from '../../../svgs/Illustration/ProductIllustration';
import LocationsIllustration from '../../../svgs/Illustration/LocationsIllustration';
import CareersIllustration from '../../../svgs/Illustration/CareersIllustration';
import AboutIllustration from '../../../svgs/Illustration/AboutIllustration';
import { MegaMenuHero__illustrationContainer } from './index.styled';

const MegaMenuHero = ({ illustration }) => {
	const theme = useContext(ThemeContext);

	return (
		<MegaMenuHero__illustrationContainer>
			{illustration === 'Product' && (
				<ProductIllustration fill={theme.primaryAccent} />
			)}
			{illustration === 'Locations' && (
				<LocationsIllustration fill={theme.primaryAccent} />
			)}
			{illustration === 'Careers' && (
				<CareersIllustration fill={theme.primaryAccent} />
			)}
			{illustration === 'About' && (
				<AboutIllustration fill={theme.primaryAccent} />
			)}
		</MegaMenuHero__illustrationContainer>
	);
};

export default MegaMenuHero;
