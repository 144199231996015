import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above, below, hover } from '../../../styles/tools/media';

export const MegaMenuHero__link = styled.a`
	display: block;
	color: var(--black);
	float: left;
	flex-basis: 300px;
	flex-shrink: 0;
	flex-grow: 0;
	margin: ${gap[56]} 0;
	text-decoration: none;
	cursor: pointer;

	& > p {
		margin-bottom: ${gap[16]};
	}

	${hover(
		() => css`
			&:hover {
				text-decoration: underline;
			}
		`
	)}

	${below(
		breakpoints[960],
		() => css`
			flex-basis: 180px;
			margin: ${gap[48]} ${gap[0]};
			display: none;
			visibility: hidden;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			flex-basis: 232px;
			margin: ${gap[48]} ${gap[0]};
		`
	)}
`;

export const MegaMenuHero__illustrationContainer = styled.div`
	display: block;
	color: var(--black);
	float: left;
	flex-basis: 300px;
	flex-shrink: 0;
	flex-grow: 0;
	margin: ${gap[56]} 0;
	text-decoration: none;
	width:234px;

	& > p {
		margin-bottom: ${gap[16]};
	}

	${hover(
		() => css`
			&:hover {
				text-decoration: underline;
			}
		`
	)}

	${below(
		breakpoints[960],
		() => css`
			flex-basis: 180px;
			margin: ${gap[48]} ${gap[0]};
			display: none;
			visibility: hidden;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			flex-basis: 232px;
			margin: ${gap[48]} ${gap[0]};
		`
	)}

	& > svg {
		height: 100%;
		width: 100%;
	}
`;
