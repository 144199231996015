import React from 'react';
import LinkMegaMenu from '../../atoms/LinkMegaMenu';
import MegaMenuHero from '../../molecules/MegaMenuHero';

import {
	StyledMegaMenu,
	MegaMenu__container,
	MegaMenu__linkRow,
	MegaMenu__linkColumn,
} from './index.styled';

const MegaMenu = ({ columns, illustration }) => (
	<StyledMegaMenu>
		<MegaMenu__container>
			<MegaMenuHero illustration={illustration} />
			<MegaMenu__linkRow>
				{columns &&
					columns.map((column, index) => (
						<MegaMenu__linkColumn
							key={index}
							isWideColumn={column.isWideColumn}
						>
							<ul style={{ listStyle: 'none', padding: 0 }}>
								<MegaMenu__linkRow as="li" key={column.heroLink.id}>
									<LinkMegaMenu {...column.heroLink} type="hero" />
								</MegaMenu__linkRow>
								{column.linkRows.map((row, i) => (
									<MegaMenu__linkRow as="li" key={i}>
										<LinkMegaMenu {...row} />
									</MegaMenu__linkRow>
								))}
							</ul>
						</MegaMenu__linkColumn>
					))}
			</MegaMenu__linkRow>
		</MegaMenu__container>
	</StyledMegaMenu>
);

export default MegaMenu;
